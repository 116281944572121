import Grid from '@components/grid';
import Container from '@components/ui/container';
import SocialIcon from '@components/ui/social-icon';
import { navSchema } from '@constants/nav-schema';
import Link from 'next/link';
import {
  FaBox,
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';
import mainSocials from '@globals/socials.json';

const year = new Date().getFullYear();
const Copyright: React.FC = () => {
  const { profiles } = mainSocials;

  return (
    <Container className="mt-4">
      <Grid>
        <div className="col-span-full md:col-span-5 lg:col-span-6 md:order-none py-2 order-last text-left flex justify-start items-center">
          <p className="text-body text-left text-xs lg:text-sm leading-6 w-full">
            Copyright &copy; {year}&nbsp;
            <Link
              href="/terms-and-conditions"
              className="font-semibold text-black transition-colors duration-200 ease-in-out hover:text-body">

              {navSchema.author.name}.
            </Link>
            &nbsp;All rights reserved.
          </p>
        </div>

        <div className="col-span-full md:mb-4 md:col-span-3 lg:col-span-6 flex justify-center md:justify-end items-center">
          {profiles &&
            profiles.map((profile: any) => {
              const getIcon = () => {
                switch (profile.platform) {
                  case 'twitter':
                    return <FaTwitter />;
                  case 'linkedin':
                    return <FaLinkedinIn />;
                  case 'youtube':
                    return <FaYoutube />;
                  case 'facebook':
                    return <FaFacebookF />;

                  default:
                    return <FaBox />;
                }
              };

              return (
                <SocialIcon
                  key={`social--icon--map--${profile.id}`}
                  href={profile.url || '#!'}
                  profile={profile}
                  className="mr-4"
                >
                  {getIcon()}
                </SocialIcon>
              );
            })}
        </div>
      </Grid>
    </Container>
  );
};

export default Copyright;
