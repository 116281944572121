import CookieBar from '@components/common/cookie-bar';
import { useAcceptCookies } from '@utils/use-accept-cookies';
import Button from '@components/ui/button';
import { Toaster } from 'react-hot-toast';
import LineFooter from './footer/line-footer';
import NewHeader from './header/new-header';
import { ReactNode } from 'react';
import PreviewMode from '@components/ui/preview-mode';

interface Props {
  children: ReactNode;
  variant?: 'default' | 'fixed';
  previewMode?: boolean;
}

const Template: React.FC<Props> = ({
  children,
  variant = 'default',
  previewMode = false,
}) => {
  const { acceptedCookies, onAcceptCookies } = useAcceptCookies();

  return (
    <>
      {previewMode && <PreviewMode />}
      <div className="flex flex-col min-h-screen">
        <NewHeader variant={variant ? variant : 'default'} />
        <main
          className="relative flex-grow"
          style={{
            minHeight: '-webkit-fill-available',
            WebkitOverflowScrolling: 'touch',
          }}
        >
          {children}
        </main>
        <LineFooter />
        <Toaster
          position="bottom-center"
          toastOptions={{
            style: {
              borderRadius: 0,
              fontSize: '14px',
              borderTop: '1px solid',
              borderColor: '#1ea1dc',
              background: '#000',
              color: 'white',
            },
            loading: {
              duration: 3000,
            },
          }}
        />
        <CookieBar
          title="Telesoft use cookies to improve your browsing experience."
          hide={acceptedCookies}
          action={
            <Button
              onClick={() => onAcceptCookies()}
              variant="cta"
              invert={true}
              className="text-white"
            >
              ACCEPT
            </Button>
          }
        />
      </div>
    </>
  );
};

export default Template;
