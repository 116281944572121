/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Link from 'next/link';
import { SERVER_BASE_URL } from '@lib/constants';

type Props = {
  className?: string;
} & any;

const CMSLink: React.FC<Props> = (link) => {
  const {
    type,
    page,
    url,
    post,
    category,
    reference,
    resource,
    elementId,
    children,
    scroll = false,
    className,
    linkedInTracking,
    linkedInConversionId,
  } = link;
  const isRelativeURL = url?.indexOf('/') === 0;

  if (linkedInTracking) {
    // @ts-ignore
    window.lintrk('track', { conversion_id: linkedInConversionId });
  }

  if (type === 'page' || isRelativeURL) {
    return (
      <Link
        href={type === 'page' ? `/${page?.slug}` : url}
        scroll={scroll}
        className={className}>
        {children}
      </Link>
    );
  }

  if (type === 'reference' || isRelativeURL) {
    const { relationTo } = reference;
    const slug = reference.value.slug;
    return (
      <Link
        href={type === 'reference' ? `/${relationTo}/${slug}` : url}
        scroll={scroll}
        className={className}>
        {children}
      </Link>
    );
  }

  if (type === 'blog-post' || isRelativeURL) {
    return (
      <Link
        href={type === 'blog-post' ? `/blog/${post?.slug}` : url}
        scroll={scroll}
        className={className}>
        {children}
      </Link>
    );
  }

  if (type === 'category' || isRelativeURL) {
    return (
      <Link
        href={type === 'category' ? `/content/${category?.slug}` : url}
        scroll={scroll}
        className={className}>
        {children}
      </Link>
    );
  }

  if (type === 'resource' || isRelativeURL) {
    if (resource.isWalled) {
      return <div>{children}</div>;
    }
    return (
      (<Link
        href={
          type === 'resource'
            ? `${SERVER_BASE_URL}/resources/${resource?.filename}`
            : url
        }
        scroll={scroll}
        target={'_blank'}
        className={className}>

        {children}

      </Link>)
    );
  }

  if (type === 'scroll') {
    return (
      <a
        className={className}
        onClick={() =>
          document
            .getElementById(elementId)!
            .scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
      >
        {children}
      </a>
    );
  }

  return (
    <a href={url} className={className}>
      {children}
    </a>
  );
};

export default CMSLink;
