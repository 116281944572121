import React from 'react';
import Link from 'next/link';
import Image from "next/legacy/image";
import Text from './ui/text';
import { Article } from '@constants/types';
import { ASSET_URL } from 'lib/constants';

type ContentType = {
  resource: Article;
  className?: string;
  overlayClassName?: string;
};

function TelesoftLine() {
  return (
    <div className="h-1.5 w-0.5 bg-white mr-0.5 group-hover:bg-telesoft"></div>
  );
}

export default function ResourceItem({
  resource,
  className,
  overlayClassName,
}: ContentType) {
  const { image } = resource;
  return (
    (<Link
      href={`/blog/${resource.slug}`}
      className={`group relative col-span-full md:col-span-4 lg:col-span-6 xl:col-span-4 p-6 md:p-8 lg:p-10 min-h-[280px] lg:min-h-[340px] w-full h-full bg-black ${className}`}>

      <div
        className={`absolute inset-0 bg-gradient-to-tr from-black to-transparent z-10 ${overlayClassName}`}
      />
      <div className="z-10 w-[22px] h-[22px] group-hover:h-[100px] group-hover:w-[100px] transition-all duration-500 absolute top-6 md:top-8 lg:top-10 right-6 md:right-8 lg:right-10 border-t border-t-gray-border border-r border-r-gray-border " />
      <div className=" flex flex-col justify-between items-start w-full h-full relative">
        <div className="z-10">
          <div className="flex mb-4 opacity-20 group-hover:opacity-100 transition-opacity duration-300">
            <TelesoftLine />
            <TelesoftLine />
            <TelesoftLine />
            <TelesoftLine />
            <TelesoftLine />
            <TelesoftLine />
            <TelesoftLine />
            <TelesoftLine />
            <TelesoftLine />
            <TelesoftLine />
            <TelesoftLine />
            <TelesoftLine />
            <TelesoftLine />
            <TelesoftLine />
            <TelesoftLine />
            <TelesoftLine />
            <TelesoftLine />
            <TelesoftLine />
          </div>
          <h5 className="z-10 font-medium text-sm uppercase text-white">
            {resource?.category?.title}
          </h5>
        </div>
        <div className=" z-10 flex flex-col justify-start">
          <Text variant="headingLight" className="mb-4 font-bold text-white">
            {resource?.title}
          </Text>
          <Text variant="body" className="text-white text-opacity-70">
            {resource?.summary && resource.summary.length > 120
              ? resource.summary.substring(0, 120) + '...'
              : resource.summary}
          </Text>
        </div>
      </div>
      <Image
        className="object-cover group-hover:scale-110 transform transition duration-500"
        layout="fill"
        priority={true}
        alt={image?.alt || 'Article Image'}
        src={
          image?.filename
            ? ASSET_URL + '/' + image.sizes.thumbnail.filename
            : '/assets/images/telesoft-abstract-38.jpeg'
        }
      />

    </Link>)
  );
}
