import Text from '@components/ui/text';
import { motion } from 'framer-motion';
import Image from "next/legacy/image";
import Link from 'next/link';
import { contentListVariant } from './header-modal';

export default function ContentSpotlightItem({
  imageUrl,
  title,
  onClick,
  href,
  date,
}: {
  imageUrl: string;
  title: string;
  href: string;
  onClick: () => void;
  date: string;
}) {
  return (
    <Link passHref href={href ? href : '#!'} legacyBehavior>
      <motion.a
        onClick={onClick}
        variants={contentListVariant}
        className="group cursor-pointer flex flex-col justify-items-stretch items-start border-t border-transparent hover:border-telesoft pt-4 w"
      >
        <h5 className="text-white text-xs text-opacity-50 mb-1">{date}</h5>
        <Image
          src={imageUrl ? imageUrl : '/assets/images/telesoft-abstract-22.jpeg'}
          className="object-cover group-hover:opacity-80 transition-opacity duration-300"
          width={300}
          alt={title || ''}
          height={230}
        />
        <Text
          variant="body"
          className="font-medium max-w-xs text-base mt-2 text-white group-hover:text-body transition-colors duration-300"
        >
          {title}
        </Text>
      </motion.a>
    </Link>
  );
}
